import request from '@/utils/request'
import constant from '@/constant'

function create(){
  return request.get(constant.serverUrl + "/bus/vehicle/create");
}

function pageList(formData){
    return request.post(constant.serverUrl + "/bus/vehicle/pageList", formData);
}

function gpsHistoryList(formData){
  return request.post(constant.serverUrl + "/bus/vehicle/gpsHistoryList", formData);
}

function remove(id){
    return request.post(constant.serverUrl + "/bus/vehicle/delete/" + id);
}

function batchRemove(idList){
    return request.post(constant.serverUrl + "/bus/vehicle/batchDelete",idList,{
      headers: {
        "Content-Type": "application/json"
      }
    });
}

function add(formModel){
    return request.post(constant.serverUrl + "/bus/vehicle/add", formModel,{
        headers: {
          "Content-Type": "application/json"
        }
    });
}
function update(formModel){  
    return request.post(constant.serverUrl + "/bus/vehicle/update", formModel,{
      headers: {
        "Content-Type": "application/json"
      }
    });
}

function edit(id){
    return request.get(constant.serverUrl + "/bus/vehicle/edit/" + id);
}

function getCompanyList(){
   return request.post(constant.serverUrl + "/base/companyInfo/list/");
}

function isBindDevice(formModel){
  return request.post(constant.serverUrl + "/bus/vehicle/isBindDevice/",formModel);
}

function isExist(formModel){
  return request.post(constant.serverUrl + "/bus/vehicle/isExist/",formModel);
}


export default {
    create,pageList,remove,add,update,batchRemove,edit,
    getCompanyList,isBindDevice,isExist,gpsHistoryList
}