<template>
    <div>
        <el-dialog
        width="900px"
        :visible.sync="showDialog"
        :title="title"
        :modal-append-to-body="true"
        :append-to-body="true"
        style="text-align:left;"
        @close="closeDialog"
        :close-on-click-modal="false"
        >
            <div class="user-panel" v-loading="loading">
                <el-form ref="form" :model="formModel" :rules="ruleValidate" inline :label-width="'100px'">
                    <el-form-item label="gps编号" prop="gpsDeviceNo">
                        <el-input v-model="formModel.gpsDeviceNo" placeholder="请输入gps设备编号" style="width:300px"></el-input>
                    </el-form-item>
                    <el-form-item label="车牌号" prop="licensePlateNumber">
                        <el-input v-model="formModel.licensePlateNumber" placeholder="请输入车牌号" style="width:300px"></el-input>
                    </el-form-item>
                    <el-form-item label="设备别名" prop="deviceNo">
                        <template>
                          <el-select v-model="formModel.deviceNo" filterable placeholder="请选择设备" style="width:300px" >
                            <el-option
                              v-for="result in deviceList"
                              :key="result.id"
                              :label="result.aliasName + '-' + result.deviceNo"
                              :value="result.deviceNo">
                            </el-option>
                          </el-select>
                        </template>
                        
                    </el-form-item>
                    <el-form-item label="所属单位" prop="companyId"> 
                        <template>
                          <!-- <el-select v-model="formModel.companyId" filterable placeholder="请选择" style="width:300px">
                            <el-option
                              v-for="result in companyList"
                              :key="result.id"
                              :label="result.name"
                              :value="result.id">
                            </el-option>
                          </el-select> -->
                          <el-select-tree
                            :options="companyList"
                            v-model="formModel.companyId"
                            :size="'medium'"
                            height="200"
                            style="width:300px;"
                          ></el-select-tree>
                        </template>
                    </el-form-item>
                    <el-form-item label="运行状态" prop="status">
                        <div style="width:300px;">
                        <el-radio v-model="formModel.status" label="1">正常</el-radio>
                        <el-radio v-model="formModel.status" label="2">异常</el-radio>
                        </div>
                    </el-form-item>
                    <el-form-item label="荷载人数" prop="loadNumber">
                        <el-input v-model="formModel.loadNumber" placeholder="荷载人数" style="width:300px"></el-input>
                    </el-form-item>
                    <el-form-item label="车辆照片" prop="picture">
                      <el-upload
                        class="avatar-uploader"
                        name="photoFile"
                        :action="uploadUrl"
                        :show-file-list="false"
                        :headers="headers"
                        :data="uploadData"
                        :on-success="handleAvatarSuccess"
                      >
                        <img v-if="fileUrl" :src="fileUrl" class="avatar" />
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                      </el-upload>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="closeDialog">取 消</el-button>
                <el-button type="primary" @click="handleSubmit" :loading="submitting">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import Constant from "@/constant";
import vehicleApi from "@/api/bus/vehicle";
import companyInfoApi from "@/api/base/companyInfo";
import dataDictionaryApi from "@/api/sys/dataDictionary";
import deviceInfoApi from "@/api/base/deviceInfo";
import { getToken } from "@/utils/auth"; // get token from cookie
import SelectTree from "@/components/SelectTree";

export default {
    props: ["businessKey", "title"],
    data() {
        let isBindDevice = (rule, value, callback) => {
          var formData = new FormData();
          var deviceNo = this.formModel.deviceNo;
          var id = this.formModel.id;
          formData.append("deviceNo", deviceNo);
          if(id!=null){
            formData.append("id", id);
          }
          else{
            formData.append("id", "");
          }

          vehicleApi.isBindDevice(formData).then(response=>{
              var jsonData = response.data;

              if (jsonData.result) {
                var reslut = jsonData.data;
                if(!reslut){
                  return callback(new Error('该设备已经绑定其他车辆，请重新选择设备'))
                }
                else{
                  return callback()
                }
              }
          })
      };
      let isBindLicensePlateNumber = (rule, value, callback) => {
        var formData = new FormData();
        var licensePlateNumber = this.formModel.licensePlateNumber;
        var id = this.formModel.id;
        formData.append("licensePlateNumber", licensePlateNumber);
        formData.append("id", id);

        vehicleApi.isExist(formData).then(response=>{
            var jsonData = response.data;

            if (jsonData.result) {
              var reslut = jsonData.data;
              if(!reslut){
                return callback(new Error('该车牌号已存在，请重新输入车牌号'))
              }
              else{
                return callback()
              }
            }
        })
      };
      return {
            formModel: {
              id: "",
              gpsDeviceNo: "",
              status:"",
              licensePlateNumber:"",
            },
            ruleValidate: {
                gpsDeviceNo: [{ required: true, message: "gps设备编号不能为空", trigger: "blur" }],
                licensePlateNumber: [
                  { required: true, message: "车牌号不能为空", trigger: "blur" },
                  { validator: isBindLicensePlateNumber, trigger: "blur" }
                ],
                deviceNo: [
                  { required: true, message: "设备不能为空", trigger: "blur" },
                  { validator: isBindDevice, trigger: 'blur'}
                ],
                companyId: [{ required: true, message: "所属单位不能为空", trigger: "blur" }],
                loadNumber: [{ required: true, message: "荷载不能为空", trigger: "blur" }]
            },

            companyList: [],
            //上传地址
            uploadUrl: Constant.serverUrl + "/uploadPicture",
            headers: {
              Authorization: getToken()
            },
            uploadData: {
              subFolder: "vehicleInfo"
            },
            fileUrl: "",
            showDialog: true,
            loading: false,
            submitting: false,
            showMapDialog: false,
            selectedRow: null,
            deviceList:[]
          };
    },
  created() {
    var self = this;

    companyInfoApi.treeList().then(function (response) {
      var jsonData = response.data;
      self.companyList = jsonData.data;
    });

    deviceInfoApi.allDevicelist().then(response => {
      var jsonData = response.data;
      this.deviceList = jsonData.data;
    });
  },
  methods: {
    closeDialog() {
      this.$emit("close", false);
    },
    handleSubmit() {
      var self = this;
      this.$refs["form"].validate(valid => {
        if (valid) {
          (function() {
            var id = self.formModel.id;
            if (id == null || id.length == 0) {
              return vehicleApi.add(self.formModel);
            } else {
              return vehicleApi.update(self.formModel);
            }
          })().then(function(response) {
            var jsonData = response.data;

            if (jsonData.result) {
              self.$message({
                message: "保存成功!",
                type: "success"
              });
              self.$emit("close", {
                result: true,
                data: jsonData.data
              });
            } else {
              self.$message({
                message: jsonData.message + "",
                type: "warning"
              });

              self.$emit("close", {
                result: false
              });
            }
          });
        }
      });
    },
    handleAvatarSuccess(res, file) {
      var self = this;
      self.formModel.picture = res.data;
      self.fileUrl =
        res.data + "?x-oss-process=image/resize,m_lfit,h_400,w_400";
    },
    beforeAvatarUpload(file) {
        const isJPG = file.type === 'image/jpeg';
        const isLt2M = file.size / 1024 / 1024 < 2;

        if (!isJPG) {
          this.$message.error('上传头像图片只能是 JPG 格式!');
        }
        if (!isLt2M) {
          this.$message.error('上传头像图片大小不能超过 2MB!');
        }
        return isJPG && isLt2M;
    }
  },
  async mounted() {
      console.log("mounted");

      var self = this;

      (function() {
        if (self.businessKey.length == 0) {
          return vehicleApi.create();
        } else {
          return vehicleApi.edit(self.businessKey);
        }
      })()
        .then(response => {
          var jsonData = response.data;

          if (jsonData.result) {

            self.formModel = jsonData.data;
            let picture = self.formModel.picture;
            if (picture != null) {
              self.fileUrl =
                picture + "?x-oss-process=image/resize,m_lfit,h_400,w_400";
            }
          } else {
            self.$message.error(jsonData.message + "");
          }
        })
        .catch(error => {
          self.$message.error(error + "");
        });
    },
    components: {
      "el-select-tree": SelectTree
    }
}
</script>
<style scoped>
.user-panel {
  margin: 10px auto;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
  object-fit: cover;
}
</style>
