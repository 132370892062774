import request from '@/utils/request'
import constant from '@/constant'

function pageList(formData) {
  return request.post(constant.serverUrl + "/base/deviceInfo/pageList", formData);
}

function create() {
  return request.get(constant.serverUrl + "/base/deviceInfo/create");
}

function edit(id) {
  return request.get(constant.serverUrl + "/base/deviceInfo/edit/" + id);
}

function add(formModel) {
  return request.post(constant.serverUrl + "/base/deviceInfo/add", formModel, {
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function update(formModel) {
  return request.post(constant.serverUrl + "/base/deviceInfo/update", formModel, {
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function remove(id) {
  return request.post(constant.serverUrl + "/base/deviceInfo/delete/" + id);
}

function batchRemove(idList) {
  return request.post(constant.serverUrl + "/base/deviceInfo/batchDelete", idList, {
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function getByIpAddressAndPort(formData) {
  return request.post(constant.serverUrl + "/base/deviceInfo/getByIpAddressAndPort", formData);
}

function list() {
  return request.post(constant.serverUrl + "/base/deviceInfo/list");
}

function allDevicelist() {
  return request.post(constant.serverUrl + "/base/deviceInfo/allDevicelist");
}

function getDeviceRule(formData) {
  return request.post(constant.serverUrl + "/base/deviceInfo/getDeviceRule", formData);
}

function saveDeviceRule(formData) {
  return request.post(constant.serverUrl + "/base/deviceInfo/saveDeviceRule", formData);
}

function query(formData) {
  return request.post(constant.serverUrl + "/base/deviceInfo/query", formData);
}

function bindDeviceToPerson(formData) {
  return request.post(constant.serverUrl + "/base/deviceInfo/bindDeviceToPerson", formData);
}

function queryBindDeviceProgress(deviceId) {
  return request.get(constant.serverUrl + `/base/deviceInfo/queryBindDeviceProgress?deviceId=${deviceId}`);
}

function reboot(formData) {
  return request.post(constant.serverUrl + "/base/deviceInfo/reboot", formData);
}

function channelList() {
  return request.get(constant.serverUrl + "/base/deviceInfo/channelList");
}

function personLibList(deviceNo) {
  return request.get(constant.serverUrl + `/devOps/queryPeopleLib?deviceNo=${deviceNo}`);
}

function personList(formData) {
  return request.post(constant.serverUrl + "/devOps/queryPeople", formData);
}

function deletePerson(deviceNo, libId, personId) {
  var formData = new FormData();
  formData.append("deviceNo", deviceNo);
  formData.append("libId", libId);
  formData.append("personId", personId);

  return request.post(constant.serverUrl + "/devOps/deletePeople", formData);
}

function batchDeletePerson(deviceNo, libId,idList) {
  var formData = new FormData();
  formData.append("deviceNo", deviceNo);
  formData.append("libId", libId);
  formData.append("idList", idList);

  return request.post(constant.serverUrl + "/devOps/batchDeletePeople", formData);
}

function existPersonImageInDevice(formData) {
  return request.post(constant.serverUrl + "/base/deviceInfo/existPersonImageInDevice", formData);
}

function updateDeviceData(formData) {
  return request.post(constant.serverUrl + "/base/deviceInfo/updateDeviceData", formData);
}

function queryBindCompanyList(formData) {
  return request.post(constant.serverUrl + "/base/deviceInfo/queryBindCompanyList", formData);
}

function unbindCompany(formData) {
  return request.post(constant.serverUrl + "/base/deviceInfo/unbindCompany", formData);
}

function pageListLoudspeaker(formData) {
  return request.post(constant.serverUrl + "/base/deviceInfo/pageListLoudspeaker", formData);
}

function bindLoudspeaker(formData) {
  return request.post(constant.serverUrl + "/base/deviceInfo/bindLoudspeaker", formData);
}

function sendCommand(data) {
  return request.post(constant.serverUrl + "/base/deviceInfo/sendCommand",data,{
      headers: {
      "Content-Type": "application/json"
      }
  });
}

export default {
  create, edit, add, update, remove, batchRemove, pageList, getByIpAddressAndPort, list,
  getDeviceRule, saveDeviceRule, query, bindDeviceToPerson, queryBindDeviceProgress, reboot,
  channelList, personLibList, personList, deletePerson, existPersonImageInDevice,
  updateDeviceData,allDevicelist,batchDeletePerson,queryBindCompanyList,unbindCompany,pageListLoudspeaker,
  bindLoudspeaker,sendCommand
}