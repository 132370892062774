<template>
    <div class="route-list">
        <el-breadcrumb separator=">">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>
                <a href="#">校车管理</a>
            </el-breadcrumb-item>
            <el-breadcrumb-item>
                <a href="#">校车管理</a>
            </el-breadcrumb-item>
        </el-breadcrumb>
        <el-divider></el-divider>
        <el-form ref="queryForm" :model="queryModel" inline class="demo-form-inline">
            <el-form-item label="车牌号" prop="licensePlateNumber">
                <el-input v-model="queryModel.licensePlateNumber" size="mini"/>
            </el-form-item>
            <el-form-item label="运行状态" prop="status">
                <el-select v-model="queryModel.status" size="mini" placeholder="请选择">
                    <el-option
                    v-for="item in statusData"
                    :key="item.id"
                    :label="item.name"
                    :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button
                type="primary"
                size="mini"
                icon="ios-search"
                @click="changePage(1)"
                :loading="loading"
                >查询</el-button>&nbsp;
                <el-button
                    type="info"
                    size="mini"
                    style="margin-left: 8px"
                    @click="handleReset('queryForm')"
                >重置</el-button>
            </el-form-item>            
        </el-form>
         <el-divider></el-divider>
        <el-row class="button-group">
            <el-button type="primary" size="small" plain icon="el-icon-circle-plus" @click="handleAdd(null)" >新增</el-button>
            <el-button
            type="primary"
            size="small"
            plain
            icon="el-icon-remove"
            :disabled="multipleSelection.length==0"
            @click="handleBatchDelete"
        >删除选中项</el-button>
        </el-row>
        <el-table
            ref="formTable"
            :data="tableData"
            v-loading="loading"
            element-loading-text="拼命加载中"
            stripe
            @selection-change="handleSelectionChange"
        >
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column prop="gpsDeviceNo" label="gps设备编号" width="150"></el-table-column>
            <el-table-column prop="licensePlateNumber" label="车牌号"></el-table-column>
            <el-table-column prop="aliasName" label="设备别名"></el-table-column>
            <el-table-column prop="picture" label="车辆照片" >
                <template slot-scope="{row}">
                <a v-if="row.picture" :href="row.picture" target="_blank">
                    <el-image
                    :size="50"
                    :src="row.picture+'?x-oss-process=image/resize,m_fill,w_64,h_64'"
                    :key="row.id"
                    ></el-image>
                </a>
                </template>
            </el-table-column>
            <el-table-column prop="loadNumber" label="荷载人数"></el-table-column>
            <el-table-column prop="statusN" label="运行状态"></el-table-column>
            <el-table-column prop="companyName" label="所属单位"></el-table-column>
            <el-table-column label="经纬度">
                <template slot-scope="{row}">
                    {{row.longitude}},{{row.latitude}}
                </template>        
            </el-table-column>
            <el-table-column prop="online" label="在线状态">
                <template slot-scope="{row}">
                    <span v-if="row.online" @click="showMap(row)" style="cursor:pointer;color:green;">
                        <i class="el-icon-map-location"></i>在线
                    </span>
                    <span v-else>不在线</span>
                </template>
            </el-table-column>
            <el-table-column prop="acc" label="ACC状态">
                <template slot-scope="{row}">
                {{row.acc ? "开启" : "关闭"}}
                </template>
            </el-table-column>
            <el-table-column prop="createTime" label="创建时间" width="150"></el-table-column>
            <el-table-column prop="updateTime" label="更新时间" width="150"></el-table-column>
            <el-table-column prop="latestTransTime" label="最后传输时间" width="150"></el-table-column>
            <el-table-column label="操作" fixed="right" width="250">
                <template slot-scope="{row}">
                    <el-button size="mini" type="warning" @click="handleEdit(row)">编辑</el-button>
                    <el-button size="mini" type="danger" @click="handleDelete(row)">删除</el-button>
                    <el-button size="mini" type="success" @click="showHistoryList(row)">历史轨迹</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div style="text-align:center;">
            <el-pagination
                :current-page.sync="pageIndex"
                :total="totalElements"
                :page-sizes="pageSizeList"
                @current-change="changePage"
                @size-change="pageSizeChange"
                layout="total, sizes, prev, pager, next, jumper"
            ></el-pagination>
        </div>
        <vehicle-detail
            v-if="showModal"
            :businessKey="businessKey"
            :parentId="selectedRecord.id"
            :title="modalTitle"
            @close="onDetailModalClose"
        ></vehicle-detail>
        <el-dialog
            :visible.sync="showMapDialog"
            title="车辆位置"
            :modal-append-to-body="true"
            :append-to-body="true"
            style="text-align:left;"
            :close-on-click-modal="false"
        >
            <el-amap
                ref="map"
                vid="vehicleMap"
                :center="pointPosition"
                :zoom="15"
                style="width:100%;height:400px;"
            >
                <el-amap-marker :position="pointPosition" :title="selectedRow.licensePlateNumber"></el-amap-marker>
            </el-amap>
            <el-input :value="pointPosition[0] + ',' + pointPosition[1]" :readonly="true"/>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showMapDialog=false;">关闭</el-button>
            </span>
        </el-dialog>
        <el-dialog
            :visible.sync="showHistoryDialog"
            title="车辆轨迹"
            :modal-append-to-body="true"
            :append-to-body="true"
            width="1000px"
            style="text-align:left;"
            :close-on-click-modal="false"
        >
            <vehicle-history-list
                ref="vehicleHistoryList"
                :deviceNo="selectedRow.gpsDeviceNo"
            ></vehicle-history-list>
        </el-dialog>
    </div>
</template>
<script>
import vehicleApi from '@/api/bus/vehicle'
import dataDictionaryApi from "@/api/sys/dataDictionary";
import VehicleDetail from "./vehicle-detail";
import VehicleHistoryList from './vehicleHistory-list';

export default {
    name:"busVehicleList",
    data(){
        return{
            queryModel: {
                licensePlateNumber: '',
                status:""
            },
            loading: false,
            tableData: [],
            statusData:[],
            multipleSelection: [],
            pageIndex: 1,
            pageSize: 10,
            totalElements: 0,
            pageSizeList: [10,20,30],
            showModal: false,
            pointPosition: [112.240222, 30.337053],            
            showMapDialog: false,
            selectedRow: {
                licensePlateNumber : "",
                deviceNo: ""
            },
            showHistoryDialog: false
        }
    },
    methods: {
        showMap(row) {
            this.pointPosition = [parseFloat(row.longitude),parseFloat(row.latitude)];
            this.selectedRow = row;
            this.showMapDialog = true;
        },
        showHistoryList(row) {
            this.selectedRow = row;
            this.showHistoryDialog = true;
            
            this.$nextTick(()=>{
                this.$refs.vehicleHistoryList.getCurrentMonthFirst();
                this.$refs.vehicleHistoryList.changePage(1);
            });            
        },
        handleReset(name) {
            this.$refs[name].resetFields();
        },
        changePage(pageIndex) {
            this.loading = true;
            var formData = new FormData();

            formData.append("pageIndex", this.pageIndex);
            formData.append("pageSize", this.pageSize);
            formData.append("licensePlateNumber", this.queryModel.licensePlateNumber);
            formData.append("status", this.queryModel.status);

            vehicleApi.pageList(formData).then((response)=>{
                this.loading = false;
                var jsonData = response.data;

                console.log(jsonData);

                this.tableData = jsonData.data.data;
                this.totalElements = jsonData.data.recordsTotal;

                this.pageIndex = pageIndex;
            })
        },
        pageSizeChange(pageSize) {
            this.pageSize = pageSize;
        },
        handleAdd(record) {
            this.modalTitle = "新增";
            this.operation = "add";
            this.businessKey = "";

            if(record!=null){
                this.selectedRecord = record;
            }
            else{
                this.selectedRecord = {};
            }

            this.showModal = true;
        },
        handleEdit(record) {
            this.modalTitle = "编辑";
            this.operation = "edit";
            this.businessKey = record.id;
            this.selectedRecord = record;
            this.showModal = true;
        },
        loopDelete(list, id) {
        var rs = false;

        for (var i = 0; i < list.length; i++) {
            if (list[i].id == id) {
            list.splice(i, 1);
            rs = true;
            break;
            }

            if (list[i].children != null) {
            rs = this.loopDelete(list[i].children, id);

            if (rs) {
                break;
            }
            }
        }

        return rs;
        },
        handleDelete(record) {
            var self = this;

            self.$confirm("是否确认删除?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
                })
                .then(() => {
                    vehicleApi.remove(record.id).then(function(response) {
                        var jsonData = response.data;

                        alert(jsonData.result);

                        if (jsonData.result) {
                        var rs = self.loopDelete(self.tableData,record.id);

                            self.$message({
                                type: "success",
                                message: "删除成功!"
                            });
                        }
                    });
                });
        },
        handleBatchDelete() {
            var self = this;

            var idList = this.multipleSelection.map(record => {
                return record.id;
            });

            this.$confirm("是否确认删除选中项？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(() => {
                vehicleApi.batchRemove(idList).then(function(response) {
                var jsonData = response.data;

                if (jsonData.result) {
                    self.changePage(self.pageIndex);

                    self.$message({
                    type: "success",
                    message: "删除成功!"
                    });
                }
                });
            });
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        onDetailModalClose(retObj) {
            //保存成功后回调
            this.showModal = false;
            if (retObj.result) {
                this.changePage(this.pageSize); 
            }
        }
    },
    components: {
        "vehicle-detail": VehicleDetail,
        "vehicle-history-list" : VehicleHistoryList
    },
    mounted() {
        this.changePage(1);
    },
    created() {
        var self = this;
        var formData = new FormData();
        formData.append("catalogName", "校车运行状态");
            dataDictionaryApi.findByCatalogName(formData).then(response => {
            var jsonData = response.data;
            this.statusData = jsonData.data;
        });
    },
}
</script>
<style lang="scss" scoped>
.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}

.el-divider {
  margin: 5px 0;
}

.route-list{
    text-align: left;
}

.demo-form-inline {
  margin-left: 10px;
  text-align: left;
}
</style>